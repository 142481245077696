import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function Service() {
  return (
    <>
      <div
        className="about-pages mt-4"
      >
        <h1>Sorry For Inconvineance This Page Will Be Updated Soon</h1>
      </div>
    </>
  );
}

export default Service;
